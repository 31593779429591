.v-container .image-container{
    border : 8px solid rgb(232 232 232/93%)
}

.v-container>div{
    flex: 1;
}

.v-right{
    gap: 0.5rem;
}
 
.accordion{
margin-top: 2rem;
border:none;

}

.accordionItem{
    background: white;
    border: 0.8px solid rgba(128,128,128,0.143);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
}

.accordionButtn{
    background: white;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
}
.icon{
    padding: 10px;
    background: #eeeeff;
    border-radius: 5px;
}

.icon svg{
    fill: var(--blue);
}

.accordionButtn .primaryText{
    font-size: 1.1rem;
}

.accordionItem.accordionButton.expanded {
    box-shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
    border-radius: 6px;
    /* other styles */
}
@media (max-width:1024px) {
    .v-container{
        flex-direction: column;
    }
    @media (max-width:768px) {
        .accordionButtn .primaryText{
            font-size: 0.8rem;
        }
        
    }
}
