.c-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}
.c-container>img{
    width: 9rem;
}

@media (max-width: 300px) {
    .c-container {

        flex-direction: column; 
     
    }
}