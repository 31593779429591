.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom:2rem;
    /* background: var(--black); */
    z-index: 1;
}

.hero-container{
    justify-content: space-around;
    align-items: flex-end;

}
.image-container{
    width: 30rem;
    height: 30rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0 ;
    border: 8px solid rgba(255, 255, 255, 0.12);
}
.image-container>img{
    width: 100%;
    height: 100%;
}

/* hero-left */

.hero-left{
    gap: 3rem;
}
.hero-tittle{
    position: relative;
    z-index: 1;
}

.hero-tittle>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}

.orange-circle{
    height: 4rem;
    width: 4rem;
    background: var(--orange-gradient);
    position: absolute;
    border-radius: 99px;
    right: 28%;
    top: -10%;
    z-index: -1;
}

.serach-bar{
    background-color: white;
    border-radius: 5px;
    border:3px solid rgba(120,120,120,0.374);
    padding: 0.5rem 1rem;
    width: 100%;
    justify-content: space-between;

}
.serach-bar>input{
    border: none;
    outline: none;
}

.stats{
    width: 100%;
    justify-content: space-between;
}

.stat>:nth-child(1){
    font-size: 2rem;

}
.stat>:nth-child(1)>:last-child{
    color: orange;
}

@media (max-width:640px){
    .hero-container{
        margin-top:2rem
    }
    .hero-tittle>h1{
        font-size: 2.5rem;
        line-height:3rem ;

    }
    .image-container{
        width: 95%;
        height: 25rem;

    }
    .stats{
        justify-content: center;
        gap: 1.5rem;
    }
    .hero-right{
        width: 100%;
    }
    .stat>:nth-child(1){
        font-size: 1.5rem;
        
    }
    .stat>:nth-child(2){
        font-size: 0.8rem;
      
    }
     .serach-bar {
      
        border: none; 
        border-bottom: 3px solid rgba(120, 120, 120, 0.374); /* Add a bottom border */
    
        width: 95%;
        
    }

    /* .serach-bar > input {
        
        width: 0; 
       
    } */

   
}

/* @media (max-width:300) {
    .serach-bar > input {
        flex-grow: 1; 
        width: 0;
        border: none;
        outline: none;
    }

    .serach-bar > button {
        flex-shrink: 0;
        margin-left: 1rem; 
    }
} */